import React, {useRef} from 'react';
import {Link} from "react-router-dom";
import {AnimatedBg, Transition} from "scroll-background";
import './App.css'
import RecentProject from "./components/RecentProjects/RecentProject";
import Video from './components/videos/Video'
import StoryMageAnimation from "./components/RecentProjects/TheStoryMage/StoryMageAnimation";
import Poetry from "./components/RecentProjects/Poetry/Poetry";
import PastProjectsHome from "./components/PastProjects/PastProjects"
import downarrow from './components/images/downarrow.svg'
import video from './components/videos/athomemiddlevideo.mp4'
import FadeInSection from "./components/FadeInSection";


function Home() {

    const scroll = useRef(null);
    const handleClick = () => {
        scroll.current?.scrollIntoView({behavior: 'smooth'});
    };
    const scroll2 = useRef(null);
    const handleClick2 = () => {
        scroll2.current?.scrollIntoView({behavior: 'smooth'});
    };
    const handleClickTop = () => {
        scroll2.current?.scrollIntoView({behavior: 'smooth'});
    };

    return (
        <>
            <AnimatedBg>
                <section className="snapscroll">
                    <header>

                        <Link to="/about" reloadDocument={true}><a
                            className="about-link fade-in-text">About</a></Link>
                    </header>
                    <p className="site-title fade-in-text">Simple Design</p>
                    <button className="hidden-btn" onClick={handleClick}><img className="arrow" src={downarrow}/>
                    </button>
                </section>

                <section className="snapscroll">
                    <div ref={scroll}/>

                    <FadeInSection>
                        <div style={{height: '100vh', margin: 'auto'}}>
                            <p className="my-intro">I'm Melissa Brown, a web developer with a keen
                                interest in contributing to the world through code and design.</p>
                            <button className="hidden-btn" onClick={handleClick2}><img alt="" className="arrow"
                                                                                       src={downarrow}/>
                            </button>
                        </div>

                    </FadeInSection></section>

                <Transition height="250px" from="#0c0c0d" to="#fff"/>


                <section className="snapscroll max-width-projects">
                    <div ref={scroll2}/>
                    <FadeInSection>
                            <p className="recent-projects">Recent Projects</p>
                            <div className="align-projects" style={{height: "76vh"}}>
                                <div className='recent-projects-container'>
                                <Link to="/athomemiddleschool" reloadDocument={true}>
                                    <RecentProject
                                        projectTitle="At Home Middle School"
                                        skill1="Gatsby"
                                        skill2="React"
                                        skill3="Design"
                                        skill4="Content"
                                        text="At Home Middle School provides free curriculum, organized into 180 daily lessons for grades 6-8. Content includes courses in: math, language arts, social studies, science, coding, Spanish, art history, and chess."
                                        video={<Video video={video}/>}
                                    /></Link>
                            </div>
                        </div>
                    </FadeInSection>
                </section>

                <section className="snapscroll max-width-projects">
                    <div className="align-projects">
                        <div className='recent-projects-container'>
                            <Link to="/thestorymage" reloadDocument={true}>
                                <RecentProject
                                    projectTitle="The Story Mage"
                                    skill1="GPT-3"
                                    skill2="React"
                                    skill3="Design"
                                    skill4="Content"
                                    text="Powered by OpenAI's GPT-3 technology, The Story Mage helps users write creative and original stories.  A bot helps them get started and moves their story along when they get stuck."
                                    video={<StoryMageAnimation/>}
                                /></Link>
                        </div>
                    </div>
                </section>

                <section className="snapscroll max-width-projects">
                    <div className="align-projects">
                        <div className='recent-projects-container'>
                            <Link to="/poetry" reloadDocument={true}>
                                <RecentProject
                                    projectTitle="100 Days of Poetry"
                                    skill1="ePub"
                                    skill2="React"
                                    skill3="Design"
                                    skill4="Content"
                                    text="An eBook of one hundred famous poems with their meanings explained. A continuous line drawing accompanies each poem."
                                    video={<Poetry/>}
                                /></Link>
                        </div>
                    </div>
                </section>


                <section className="snapscroll" style={{height: '100vh'}}>
                    <div className='recent-projects-container' style={{paddingTop: "20vh"}}>
                        <PastProjectsHome/>
                        <div className="back-to-top">
                            <button className="hidden-btn home-btn-text" onClick={handleClickTop}>Back
                                to top
                            </button>
                        </div>
                    </div>
                </section>
            </AnimatedBg>
        </>
    );
}

export default Home