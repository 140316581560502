import React, {useRef} from 'react';
import {Link} from "react-router-dom";
import "./PagesTemplate.css";
import LazyLoad from "react-lazyload";
import {AnimatedBg, Transition} from "scroll-background";
import uparrow from "../../components/images/uparrow.svg";

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
    }, []);
    return (<div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
    >
        {props.children}
    </div>);
}

const PagesTemplate = ({
                           image1,
                           projectTitle,
                           description,
                           link,
                           style,
                           image2,
                           style2,
                           style3,
                           description2,
                           testimonialImage,
                           testimonial,
                           testimonialName,
                           video,
                           previous,
                           next
                       }) => {

    const scroll = useRef(null);
    const handleClickTop = () => {
        scroll.current?.scrollIntoView({behavior: 'smooth'});
    };

    return (

        <>
            <div className="pages-container">
                <div ref={scroll}/>
                <header className="pages-project-header">
                    <Link to="/" reloadDocument={true}><a
                        className="left-top-link fade-in">HOME</a></Link>
                    <Link to="/about" reloadDocument={true}><a
                        className="about-link fade-in">About</a></Link>
                </header>

                <FadeInSection>
                    <div className='pages-project-section'>
                        <img className="pages-image" alt="" src={image1}/>
                        <div className='pages-project-description'>
                            <div className='pages-project-title'>{projectTitle}</div>
                            <p className='project-description-text'>{description} </p>
                            <div style={{paddingTop: "10px"}}><a className="launch"
                                                                 href={link}
                                                                 target='_blank'
                                                                 rel="noopener">View Project</a></div>
                        </div>
                    </div>
                </FadeInSection>

                <FadeInSection>
                    <div className={style}>
                        <img className={style2} alt="" src={image2}/>
                        <div className={style3}>
                            <p className='project-description-text'>{description2} </p>
                        </div>
                    </div>
                </FadeInSection>

                <FadeInSection>
                    <div className="testimonials">
                        <img src={testimonialImage} className="left-side" alt=""/>
                        <p className="testimonial-text">{testimonial} <br/>
                            <span className="testimonial-name">{testimonialName}</span></p>
                    </div>
                </FadeInSection>

                <AnimatedBg>
                    <Transition height="150px" from="#fff" to="#0c0c0d"/>

                    <div className='pages-project-section'>
                        <div className='pages-video'>
                            <LazyLoad className="videoWrapper">
                                <FadeInSection>
                                    <video autoPlay muted loop style={{borderRadius: "10px"}}
                                           width="100% !important">
                                        <source src={video} type="video/mp4"/>
                                    </video>
                                </FadeInSection>

                                <div className="buttons-section">
                                    <Link to={previous} reloadDocument={true}>
                                        <button className="launch hidden-btn"> Previous Site</button>
                                    </Link>
                                    <button className="hidden-btn" onClick={handleClickTop}><img
                                        className="pages-uparrow" alt="up arrow" src={uparrow}/></button>
                                    <Link to={next} reloadDocument={true}>
                                        <button className="launch hidden-btn"> Next Site</button>
                                    </Link>

                                </div>


                            </LazyLoad>
                        </div>
                    </div>


                </AnimatedBg>

            </div>
        </>
    )
}

export default PagesTemplate