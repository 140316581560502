import React from 'react';
import {Link} from "react-router-dom";
import "./css/scrollingImages.css"
import image1 from "../components/images/recentProjects/1.jpg"
import image2 from "../components/images/recentProjects/2.jpg"
import image3 from "../components/images/recentProjects/3.jpg"
import image4 from "../components/images/recentProjects/4.jpg"
import image5 from "../components/images/recentProjects/5.jpg"
import image6 from "../components/images/recentProjects/6.jpg"
import image7 from "../components/images/recentProjects/7.jpg"

const AtHomePage = () => {

    return (
        <>
            <header>
                <Link to="/about" reloadDocument={true}><a
                    className="about-link fade-in">About</a></Link>
            </header>

            <div style={{height: "50vh"}}/>

            <div className="logo-slider">
                <div>

                    <img src={image1} alt=""/>
                    <img src={image2} alt=""/>
                    <img src={image3} alt=""/>
                    <img src={image4} alt=""/>
                    <img src={image5} alt=""/>
                    <img src={image6} alt=""/>
                    <img src={image7} alt=""/>
                </div>
                <div>
                    <img src={image1} alt=""/>
                    <img src={image2} alt=""/>
                    <img src={image3} alt=""/>
                    <img src={image4} alt=""/>
                    <img src={image5} alt=""/>
                    <img src={image6} alt=""/>
                    <img src={image7} alt=""/>
                </div>
            </div>
        </>
    )
}

export default AtHomePage;