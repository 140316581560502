import React, {useRef} from 'react';
import {Link} from "react-router-dom";
import "./PagesTemplate/PagesTemplate.css";
import LazyLoad from "react-lazyload";
import uparrow from "../components/images/uparrow.svg";
import music from "../components/images/pastProjects/drupalMusic.jpg"
import nancy from "../components/images/pastProjects/nancy.jpg"
import resume from "../components/images/pastProjects/resume.jpg"
import education from "../components/images/pastProjects/education.jpg"

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
    }, []);
    return (<div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
    >
        {props.children}
    </div>);
}

const Drupal = () => {

    const scroll = useRef(null);
    const handleClickTop = () => {
        scroll.current?.scrollIntoView({behavior: 'smooth'});
    };

    return (

        <>
            <div ref={scroll}/>
            <header className="pages-project-header">
                <Link to="/" reloadDocument={true}><a
                    className="left-top-link fade-in">HOME</a></Link>
                <Link to="/about" reloadDocument={true}><a
                    className="about-link fade-in">About</a></Link>
            </header>

            <FadeInSection>
                <div className='pages-project-section'>
                    <img className="drupal-image" alt="" src={music}/>
                    <div className='drupal-description'>
                        <div className='pages-project-title'>Music</div>
                        <p className='project-description-text'>A dramatic and simple Drupal theme that is fluid and
                            responsive.</p>
                        <br/>
                        <div style={{paddingTop: "10px"}}><a className="drupal-launch"
                                                             href="https://dznmusic.simpledzn.com/"
                                                             target='_blank'
                                                             rel="noopener">View Project</a></div>
                        <br/>
                        <div style={{paddingTop: "10px"}}><a className="drupal-launch hidden"
                                                             href=""
                                                             target='_blank'
                                                             rel="noopener">Download Theme</a></div>
                    </div>
                </div>
            </FadeInSection>

            <LazyLoad>
                <FadeInSection>
                    <div className='pages-project-section'>
                        <img className="drupal-image" alt="" src={resume}/>
                        <div className='drupal-description'>
                            <div className='pages-project-title'>Resume</div>
                            <p className='project-description-text'>Striking fonts, vivid headlines, and effective
                                colors give this theme a unique and polished look.</p>
                            <br/>
                            <div style={{paddingTop: "10px"}}><a className="drupal-launch"
                                                                 href="https://www.resume.simpledzn.com"
                                                                 target='_blank'
                                                                 rel="noopener">View Project</a></div>
                            <br/>
                            <div style={{paddingTop: "10px"}}><a className="drupal-launch hidden"
                                                                 href=""
                                                                 target='_blank'
                                                                 rel="noopener">Download Theme</a></div>
                        </div>
                    </div>
                </FadeInSection>

                <FadeInSection>
                    <div className='pages-project-section'>
                        <img className="drupal-image" alt="" src={nancy}/>
                        <div className='drupal-description'>
                            <div className='pages-project-title'>Blog</div>
                            <p className='project-description-text'>An elegant and minimal design for blogs with a focus
                                on content and easy readability.</p>
                            <br/>
                            <div style={{paddingTop: "10px"}}><a className="drupal-launch"
                                                                 href="https://nancy.simpledzn.com/"
                                                                 target='_blank'
                                                                 rel="noopener">View Project</a></div>
                            <br/>
                            <div style={{paddingTop: "10px"}}><a className="drupal-launch hidden"
                                                                 href=""
                                                                 target='_blank'
                                                                 rel="noopener">Download Theme</a></div>
                        </div>
                    </div>
                </FadeInSection>

                <FadeInSection>
                    <div className='pages-project-section'>
                        <img className="drupal-image" alt="" src={education}/>
                        <div className='drupal-description'>
                            <div className='pages-project-title'>Education</div>
                            <p className='project-description-text'>This theme was designed with playful colors, clean
                                fonts and original graphics.</p>
                            <br/>
                            <div style={{paddingTop: "10px"}}><a className="drupal-launch"
                                                                 href="https://education.simpledzn.com"
                                                                 target='_blank'
                                                                 rel="noopener">View Project</a></div>
                            <br/>
                            <div style={{paddingTop: "10px"}}><a className="drupal-launch hidden"
                                                                 href=""
                                                                 target='_blank'
                                                                 rel="noopener">Download Theme</a></div>
                        </div>
                    </div>
                </FadeInSection>

                <div className="buttons-section" style={{paddingBottom: "5em"}}>
                    <Link to="/thestorymage" reloadDocument={true}>
                        <button className="launch hidden-btn"> Previous Site</button>
                    </Link>
                    <button className="hidden-btn" onClick={handleClickTop}><img
                        className="pages-uparrow" alt="up arrow" src={uparrow}/></button>
                    <Link to="/springwater" reloadDocument={true}>
                        <button className="launch hidden-btn"> Next Site</button>
                    </Link>

                </div>


            </LazyLoad>
        </>
    )
}

export default Drupal