import React from 'react';
import PagesTemplate from "./PagesTemplate/PagesTemplate";
import image from "../components/images/pastProjects/asuPages.jpg"
import video from "../components/videos/asucanoe.mp4"
import testimonialImage from "../components/images/pastProjects/asu-team.png"

const SpringwaterPage = () => {

    return (

        <>
            <PagesTemplate
                image1={image}
                projectTitle={"ASU Concrete Canoe"}
                description={"Arizona State University's Concrete Canoe Team wanted a site to showcase the work they do and the fun they have doing it. Clean aesthetics, minimalism, and effective mobile design provided the look and feel they wanted."}
                link={'https://www.asucanoe.com/'}
                video={video}
                testimonialImage={testimonialImage}
                testimonial={"\"Melissa created a site for us that is both user-friendly and beautiful. She really understood what we wanted and got it right the first time. It perfectly portrays what we do as a team and is a great marketing tool to help us recruit new members. In past years, it was a challenge getting the word out about our canoe team at ASU. Now we send interested students straight to our site. We couldn’t be happier with it!\""}
                testimonialName={"ASU Concrete Canoe Team"}
                previous={"/springwater"}
                next={"/crystal"}
            />
        </>

    )
}

export default SpringwaterPage;